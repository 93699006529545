export default [

    {
        path: '/gallery/create',
        name: 'gallery-create',
        component: () => import('@/views/gallery/gallery-form/UploadForm.vue'),
        meta: {
          resource: 'Gallery',
          action: 'create',
        },
    },
    
    
  ]
  