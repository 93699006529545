export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/users/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/users/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/users',
        name: 'user-read',
        component: () => import('@/views/users/user-list/UserList.vue'),
        meta: {
          resource: 'User',
          action: 'read',
        },
      },
  

    
]